import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { BoelsConfirmationModalComponent } from '../components/confirmation-modal/boels-confirmation-modal.component';
import { ConfirmationModalModel } from '../models/dialog.model';

@Injectable({
  providedIn: 'root',
})
export class ModalsService {
  constructor(
    private readonly dialog: MatDialog,
    private readonly translateService: TranslateService
  ) {}

  /**
   * Opens a discard modal dialog.
   *
   * @param customTranslations Optional custom translations for the modal content.
   * @returns A promise that resolves to a boolean indicating whether the modal was closed with a confirmation.
   */

  public async openDiscardModal(customTranslations?: DiscardModalTranslations): Promise<boolean> {
    const data: Partial<ConfirmationModalModel> = {
      heading: this.translateService.instant(customTranslations?.heading ?? 'discardModal.discardChanges'),
      mainContentMessage: this.translateService.instant(
        customTranslations?.discardBodyMsg ?? 'discardModal.discardBodyMsg'
      ),
      cancelButtonText: this.translateService.instant(
        customTranslations?.btnContinueEditing ?? 'discardModal.continueEditing'
      ),
      confirmButtonText: this.translateService.instant(customTranslations?.btnDiscard ?? 'button.discard'),
    };
    const dialog: MatDialogRef<BoelsConfirmationModalComponent, boolean> = this.dialog.open(
      BoelsConfirmationModalComponent,
      { data, restoreFocus: false, disableClose: true }
    );
    return firstValueFrom(dialog.afterClosed());
  }
}

interface DiscardModalTranslations {
  heading: string;
  discardBodyMsg: string;
  btnContinueEditing: string;
  btnDiscard: string;
}
