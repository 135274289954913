import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
} from '@angular/material/dialog';
import { ConfirmationModalModel } from '../../models/dialog.model';
import { CdkScrollable } from '@angular/cdk/scrolling';

@Component({
  selector: 'boels-confirmation-modal',
  templateUrl: './boels-confirmation-modal.component.html',
  styleUrls: ['./boels-confirmation-modal.component.scss'],
  standalone: true,
  imports: [MatDialogTitle, CdkScrollable, MatDialogContent, MatDialogActions],
})
export class BoelsConfirmationModalComponent {
  public content: ConfirmationModalModel;

  constructor(
    public dialogRef: MatDialogRef<BoelsConfirmationModalComponent, boolean>,
    @Inject(MAT_DIALOG_DATA)
    public readonly data: ConfirmationModalModel
  ) {}

  public cancel(): void {
    this.dialogRef.close(false);
  }

  public confirm(): void {
    this.dialogRef.close(true);
  }
}
