<div class="component-confirmation-modal">
  @if (data?.heading; as text) {
    <header mat-dialog-title>
      <h2>{{ text }}</h2>
    </header>
  }

  @if (data?.mainContentMessage; as html) {
    <div mat-dialog-content>
      <div [innerHTML]="html"></div>
    </div>
  }

  <div mat-dialog-actions>
    <div class="component-confirmation-modal__buttons">
      @if (data?.cancelButtonText; as text) {
        <button class="btn btn-outlined" (click)="cancel()">
          {{ text }}
        </button>
      }
      @if (data?.confirmButtonText; as text) {
        <button class="btn btn-primary" (click)="confirm()">
          {{ text }}
        </button>
      }
    </div>
  </div>
</div>
